import React from 'react'
import { Link } from 'gatsby'

import { useCustomer, useLogin, useAllocation } from 'gatsby-theme-harlan'

// import app components
import logoSVGDark from "../images/harlan-estate-logo-dark.svg"
import logoSVGLight from "../images/harlan-estate-logo-light.svg"

const Header = (props) => {
    const {
      location: { pathname },
    } = props

    const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
    const { logout } = useLogin()
    const { isAllocationLoading, allocationStatus } = useAllocation()

    const isBrowser = typeof window !== "undefined"
    const isHomePage = pathname === "/"

    const shouldHideLinks =
        isBrowser && window.location.pathname.includes("login-by-link")

    // if (!isBrowser) {
    //   return null
    // }

    return (
      <header
        className={`main-header flex flex-col items-center ${
          isHomePage ? " home-header" : ""
        }`}
      >
        <div>
          <Link to="/">
            <img
              className="harlan-logo"
              src={isHomePage ? logoSVGLight : logoSVGDark}
              alt="Harlan Estate"
            />
          </Link>
        </div>
        <nav
          id="main-navigation"
          className={`mt-4 ${isHomePage ? "light-menu" : ""}`}
        >
          <ul className="w-full flex flex-row flex-wrap justify-center h-6">
            {!isCustomerLoading && (!isLoggedIn || !isAllocationLoading) && (
              <>
              {isLoggedIn && !isAllocationLoading && (allocationStatus == 'offering' || allocationStatus == 'thank-you') ? (
                <>
                  <li>
                    <Link to="/offering/letter" activeClassName="link_isActive">
                      Letter
                    </Link>
                  </li>
                  <li>
                    <Link to="/offering/allocation" activeClassName="link_isActive">
                      Allocation
                    </Link>
                  </li>
                  <li>
                    <Link to="/offering/vintage-notes" activeClassName="link_isActive">
                      Vintage Notes
                    </Link>
                  </li>
                </>
              ) : (
              <>
                <li>
                  <Link to="/purpose" activeClassName="link_isActive">
                    Purpose
                  </Link>
                </li>
                <li>
                  <Link to="/place" activeClassName="link_isActive">
                    Place
                  </Link>
                </li>
                <li>
                  <Link to="/people" activeClassName="link_isActive">
                    People
                  </Link>
                </li>
                <li>
                  <Link to="/wine" activeClassName="link_isActive">
                    Wine
                  </Link>
                </li>
                {isLoggedIn ? (
                  <>
                    <li>
                      <Link
                        to="/profile"
                        activeClassName="link_isActive"
                      >
                        Account
                      </Link>
                    </li>
                    <li>
                      <a
                        className={'cursor-pointer'}
                        onClick={logout}
                      >
                        Logout
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link
                        to={"/allocation"}
                        activeClassName="link_isActive"
                      >
                        Allocation
                      </Link>
                    </li>
                    <li>
                      <Link to="/login" activeClassName="link_isActive">
                        Members
                      </Link>
                    </li>
                  </>
                )}
                </>
              )}
            </>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default Header

import i18next from '@theme/translations'

const siteConfig = {
  winery: 'harlan',
  metadata: {
    title: `Harlan Estate`,
    description: `Harlan Estate was founded in 1984 with the vision of producing a wine—a 'First Growth’ of California—from the western hills of Oakville, in the heart of Napa Valley.`,
    phone: '(707) 944-1441',
    wines: 'Harlan Estate and The Maiden',
  },
  loginPath: '/login',
  loginTitle: 'Members',
  signupPath: '/allocation',
  signupTitle: 'Request Allocation',
  countOfInHouseInventoryLocations: 2,
  director: {
    name: 'François Vignaud',
    nameWithTitle: 'François Vignaud',
    email: 'francois@harlanestate.com',
  },
  patronRelations: {
    name: 'Lisa Scott',
    email: 'lisa@harlanestate.com',
    hospitalityEmail: 'laura@harlanestate.com',
    hospitalityPhone: '(707) 944-1441',
  },
  isFlacActive: false,
  nextVintageWines: '2023 vintage of Harlan Estate and 2022 The Maiden',
  lastVintageWines: '2022 Harlan Estate or the 2021 vintage of The Maiden',
  settings: {
    skipLetterDestination: '/allocation',
    useCustomerLetters: true,
  },
}

if (process.env.GATSBY_SENTRY_ENV == 'development') {
  siteConfig.ccWorkaroundInventoryLocationId = '8814015d-f5a6-11e8-a80a-06d2aa911ccc' // "harlan-estate" instance, HE Office
} else {
  siteConfig.ccWorkaroundInventoryLocationId = '3ad8e8d5-b6d1-4303-8027-e4f6947d3f1e' // "harlan-estate-production", HE "Warehouse"
}

export default siteConfig

import React from 'react'
import { Link } from 'gatsby'

import { useCustomer, useLogin, useAllocation } from 'gatsby-theme-harlan'

const Footer = () => {
  const { customer, isLoggedIn, isCustomerLoading } = useCustomer()
  const { logout } = useLogin()
  const { isAllocationLoading, allocationStatus } = useAllocation()

  return (
    <footer className="text-center pt-16 pb-4">
      <div id="footer" className="description w-full">
        <div className="nav-menu">
          {!isCustomerLoading && (
            <>
              {isLoggedIn && !isAllocationLoading && (allocationStatus == 'offering' || allocationStatus == 'thank-you') && (
                <Link className="link" to="/">
                  Public Website
                </Link>
              )}
              <Link className="link" to="/bottle-verification">
                Bottle Verification
              </Link>
              {isLoggedIn ? (
                <>
                  <Link className="link" to="/profile">
                    Account
                  </Link>
                  <a className={'link cursor-pointer'} onClick={logout}>
                    Logout
                  </a>
                </>
              ) : (
                <Link className="link" to="/login">
                  Login
                </Link>
              )}
            </>
          )}
        </div>
        <div className="contact-info">
          <div className="w-full sm:w-fit pb-2 md:pb-0 px-2">
            <p>P.O. Box 352, Oakville, California 94562</p>
          </div>
          <div className="w-fit pb-2 sm:pb-0 px-2">
            <p>
              <a href="tel:7079441441">(707) 944-1441</a>
            </p>
          </div>
          <div className="w-fit pb-2 sm:pb-0 px-2">
            <p>
              <a href="mailto:info@harlanestate.com">info@harlanestate.com</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
